import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import TopMenu from "views/TopMenu";
import Navbar from "views/Navbar";
import Top from "views/Top";
import Footer from "views/Footer";
import * as Sections from "views/Sections";
import SEO from "components/SEO";

import "utils/fixFontAwesome";
import breakDownAllNodes from "utils/breakDownAllNodes";
import fileNameToSectionName from "utils/fileNameToSectionName";
import fileToComponentName from "utils/fileToComponentName";
import "../style/main.scss";

/**
 * get file name list from content/sections folder
 */
export const query = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        keywords
        description
      }
    }
    allMarkdownRemark(sort: { order: ASC, fields: [fields___directoryName, fields___fileName] }) {
      nodes {
        frontmatter {
          brand
          anchor
          clients {
            href
            imageFileName
          }
          supporters {
            href
            imageFileName
          }
          content
          copyright
          header
          imageFileName
          urlFirst
          urlFirstText
          urlSecond
          urlSecondText
          menuText
          portfolios {
            content
            extraInfo
            header
            slug
            subheader
            imageFileNameDetail
            imageFileName
          }
          services {
            header
            iconName
            imageFileName
          }
          social {
            facebook
            github
            linkedin
            medium
            twitter
          }
          subheader
          title
          timeline {
            content
            header
            imageContent
            imageFileName
            subheader
          }
          video {
            header
            videoSrcURL
          }
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const {
    site: {
      siteMetadata: { keywords, description },
    },
    allMarkdownRemark: { nodes },
  } = data;

  const { topNode, navBarNode, anchors, footerNode, sectionsNodes } = breakDownAllNodes(
    nodes,
    "frontpage",
  );
  const pages = [
    {
      url: "/",
      title: "HOME",
    },
    {
      url: "/competence-center/",
      title: "Competence Center",
    },
    {
      url: "/toolkit/",
      title: "Toolkit",
    },
    {
      url: "/onboarding/",
      title: "Onboarding",
    },
  ];

  return (
    <>
      <SEO title="Home of Mobile Europeans (HOME)" keywords={keywords} description={description} />
      <TopMenu frontmatter={navBarNode.frontmatter} extraItems={pages} />
      <Top frontmatter={topNode.frontmatter} />
      <Navbar anchors={anchors} frontmatter={navBarNode.frontmatter} extraItems={pages} />
      {
        // dynamically import sections
        sectionsNodes.map(({ frontmatter, fields: { fileName } }, ind) => {
          const sectionComponentName = fileToComponentName(fileName);
          const SectionComponent = Sections[sectionComponentName];

          return SectionComponent ? (
            <SectionComponent
              key={sectionComponentName}
              className={ind % 2 === 1 ? "bg-light" : null}
              frontmatter={frontmatter}
            />
          ) : null;
        })
      }

      <Footer frontmatter={footerNode.frontmatter} />
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default IndexPage;
